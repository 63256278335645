<template>
  <v-row class="text-center">
    <v-col cols="12">
      <img :src="done" class="mb-4" />
      <h1 class="mb-2 font-family-raleway-bold mobile-style">
        {{ $t("all_done") }}
      </h1>
      <v-btn
        class="button font-family-raleway-bold mt-6"
        height="60"
        @click="clickExplore"
      >
        {{$t("explore_campaign")}}
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import done from "@/assets/img/done.svg";

export default {
  name: "AllDone",
  data() {
    return {
      done,
    };
  },
  created() {
    localStorage.removeItem("campaign");
    localStorage.removeItem("allKeywords");
  },
  methods: {
    clickExplore() {
      window.location.replace("/my-campaigns");
    },
  },
};
</script>
<style scoped>
@media screen and (max-width: 800px) {
  .mobile-style{
    width: 80%;
    margin: 0 auto;
  }
  .button{
    padding: 0 16px!important;
  }
}
</style>
